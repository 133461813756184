<template>
  <div class="chart">
    <div class="top">
      <div class="tabs">
        <p v-for="item in tabs" :key="item.id" :class="{p_active:item.id === activeTab}" @click="changeTab(item.id)">
          {{item.name}}</p>
        <div class="tab_active" :style="`left:${leftNum}rem`"></div>
      </div>
      <p class="p1">您的积分：<span>{{myChart.points}}</span> 分</p>
      <p class="p1">您的排名：<span>{{myChart.rank}}</span></p>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <main>
        <ul>
          <li v-for="(item,index) in chartList" :key="item.id">
            <img v-if="item.user" :src="item.user.avatar" class="header-img">
            <img v-if="index === 0" src="~img/52.png" class="icon-img">
            <img v-else-if="index === 1" src="~img/53.png" class="icon-img">
            <img v-else-if="index === 2" src="~img/54.png" class="icon-img">
            <div class="doc">
              <div class="li-top">
                <p class="p1" v-if="item.user">{{item.user.name}}</p>
                <p class="p2">积分：{{item.points}}分</p>
              </div>
              <p class="p3">公益时长：{{item.diff_time}}小时</p>
              <p class="p3" v-if="item.user">参与活动次数：{{item.user.activuty_count}}次</p>
            </div>
          </li>
        </ul>
      </main>
    </van-list>
    <tabbar />
  </div>
</template>

<script>
import tabbar from "../components/Tabbar.vue";
import wxShare from "../utils/wxShare.js";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      activeTab: 1,
      tabs: [
        { id: 1, name: "积分排名" },
        { id: 2, name: "公益时长排名" },
      ],
      leftNum: 0,
      limit: 5,
      page: 0,
      type: "points",
      loading: false,
      finished: false,
      chartList: [],
      myChart: {},
    };
  },
  created() {
    this.activeTab = this.getQueryValue("id");
    if (this.activeTab == 1) {
      this.type = "points";
      this.leftNum = 0;
    } else {
      this.type = "diff_time";
      this.leftNum = 2.1;
    }
    this.getMyChart();
  },
  mounted() {
    wxShare.commShare("rank");
  },
  methods: {
    changeTab(id) {
      this.activeTab = id;
      if (id === 1) {
        this.leftNum = 0;
        this.type = "points";
      } else {
        this.leftNum = 2.1;
        this.type = "diff_time";
      }
      this.page = 1;
      this.chartList = [];
      this.finished = false;
      this.getChartList();
      this.getMyChart();
    },
    async getMyChart() {
      const res = await this.axios.get(`user/rank/my?type=${this.type}`);
      console.log(res);
      if (res.code === 200) this.myChart = res.data;
    },
    onLoad() {
      this.page++;
      this.getChartList();
    },
    async getChartList() {
      this.loading = true;
      const res = await this.axios.get(
        `user/rank?limit=${this.limit}&page=${this.page}&type=${this.type}`
      );
      if (res.code === 200) {
        console.log(res);
        const { list, meta } = res.data;
        list.forEach((item) => {
          this.chartList.push(item);
        });
        this.loading = false;
        if (this.chartList.length >= meta.total) this.finished = true;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.chart {
  position: relative;
  font-family: FZLTHJW--GB1-0, FZLTHJW--GB1;
  padding-top: 285px;
  .top {
    width: 100%;
    height: 357px;
    position: absolute;
    top: 0;
    background: url("../assets/img/51.png") no-repeat;
    background-size: contain;
    padding-top: 35px;
    padding-left: 31px;
    .tabs {
      display: flex;
      position: relative;
      margin-bottom: 48px;
      p {
        font-size: 27px;
        color: #fff;
        margin-right: 45px;
      }
      .p_active {
        font-size: 30px;
        font-weight: 500;
      }
      .tab_active {
        width: 58px;
        height: 6px;
        background: #fff;
        position: absolute;
        bottom: -15px;
        transition: 0.2s all;
      }
    }
    .p1 {
      font-size: 28px;
      color: #fff;
      span {
        font-size: 36px;
      }
    }
  }
  main {
    position: relative;
    min-height: 50px;
    ul {
      width: 92%;
      background: #fff;
      border-radius: 18px;
      margin: auto;
      padding-left: 31px;
      padding-right: 29px;
      li {
        height: 167px;
        padding-left: 61px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e4e4e4;
        position: relative;
        .header-img {
          width: 119px;
          height: 119px;
          border-radius: 50%;
        }
        .doc {
          flex: 1;
          padding-left: 24px;
          .li-top {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            .p1 {
              font-size: 28px;
            }
            .p2 {
              font-size: 21px;
            }
          }
          .p3 {
            font-size: 24px;
            color: #6f6f6f;
          }
        }
        .icon-img {
          width: 40px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
      li:last-child {
        border: none;
      }
    }
  }
}
</style>